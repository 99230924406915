import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import _ from 'lodash'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import config from './config'
import TOS from './TOS'

const deepMap = require('deep-map')
const uuidv4 = require('uuid/v4')

const log = (type: any) => console.log.bind(console, type)

// Min length 6 chars, mixing letters and numbers, or letters and special chars.
const passwordPattern = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/

type AppProps = any

@observer
class Login extends React.Component<AppProps> {
  @observable
  enforceAuthentication: string | null = null

  @observable
  passwordInput: string | undefined = undefined

  @observable
  validated: boolean = false

  @observable
  redirect: string | null = null

  @observable
  uid: string | null = null

  @observable
  submitError: string | null = null

  constructor (props: any, context: any) {
    super(props, context)

    runInAction(() => {
      this.uid = window.localStorage.getItem('uid')
    })

  }

  @bind
  async testLogin(id: string, passPhrase: string) {
    //console.log(`Getting data ${id} with ${passPhrase}`)
    let options: any = {
      url: config.API_BASE_URL + '/' + id,
      method: 'GET',
      headers: {
        Authorization: `Basic ${btoa(passPhrase)}`
      }
    }
    
    try {
      let res: any = await axios(options)
      // let parsed = JSON.parse(res.data)
      return true
    } catch (err) {
      if (err.response && (err.response.status === 401 || err.response.status === 404)) {
        console.error(err.response.data)
        runInAction(() => {
          this.submitError = 'Falsche Unique ID oder falsches Passwort'
        })
      } else {
        runInAction(() => {
          console.error('Unkown error, try again later.', err)
        })
      }

      return false
    }
  }

  render () {
    if (this.redirect) {
      console.log(`Redirecting from Login to ${this.redirect}`)

      return <Redirect to={`/${this.redirect}`} push/>
    }

    return (
      <div className='App'>
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          <div className='App-form'>
            <h2>Login zu bestehendem Planspiel</h2>

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ password: '', uid: ''}}
              onSubmit={async (values, { setSubmitting }) => { 
                  setSubmitting(true)
                  if (await this.testLogin(values.uid, values.password)) {
                    window.sessionStorage.setItem('passPhrase', btoa(values.password))
                    runInAction(() => this.redirect = 'bearbeiten/' + values.uid)
                  }
                }
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                // @ts-ignore
                <Form onSubmit={handleSubmit} noValidate validated={this.validated}>
                  <Form.Group controlId='uid'>
                    <Form.Label>Unique ID</Form.Label>
                    <Form.Control placeholder='Gib die ID von eurem Dokument ein' value={values.uid} onChange={handleChange} onBlur={handleBlur} />
                  </Form.Group>
                  <Form.Group controlId='password'>
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control type='password' placeholder='Wähle ein Passwort' value={values.password} onChange={handleChange} onBlur={handleBlur} />
                  </Form.Group>
                  <Button variant='primary' type='submit'>
                    Los gehts!
                  </Button>
                </Form>
              )}
            </Formik>
            {
             this.submitError &&
              <div className='App-server-error'>{this.submitError}</div>
            }
          </div>
        </div>
        <TOS/>
      </div>
    )
  }

}
export default Login
