export default {
  "title": "Wir sind das Parlament",
  "description": "Welches Ziel möchtet ihr mit eurer Initiative erreichen? Wer spielt welche Rolle? Wer hat was gesagt? Mit der vorliegenden Eingabemaske könnt ihr eure politische Beratung in Wort und Bild dokumentieren und zum Schluss als PDF herunterladen. Mit klicken auf «Speichern» wird der aktuelle Stand zwischengespeichert. Mit Klicken auf «Erzeuge PDF» wird die Dokumentation als PDF dargestellt.",
  "type": "object",
  "additionalProperties": false,
  "definitions": {
    "picture": {
      "title": "Upload Bild (idealerweise quadratisches Bild, Grösse muss <3MB betragen)",
      "type": [
        "string",
        "null"
      ],
      "format": "uri"
    },
    "person": {
      "type": "object",
      "properties": {
        "vorname": {
          "title": "Vorname",
          "type": "string"
        },
        "nachname": {
          "title": "Name",
          "type": "string"
        },
        "bild": {
          "$ref": "#/definitions/picture"
        }
      }
    },
    "politiker": {
      "type": "object",
      "properties": {
        "vorname": {
          "title": "Vorname",
          "type": "string"
        },
        "nachname": {
          "title": "Name",
          "type": "string"
        },
        "partei": {
          "title": "Partei",
          "type": "string"
        },
        "bild": {
          "$ref": "#/definitions/picture"
        }
      }
    }
  },
  "properties": {
    "klasse": {
      "title": "Angaben zur Klasse",
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "klasse": {
          "title": "Klasse",
          "type": "string"
        },
        "schule": {
          "title": "Schulort",
          "type": "string"
        },
        "lehrer": {
          "title": "Lehrperson",
          "type": "string"
        },
        "datum": {
          "title": "Datum",
          "type": "string",
          "format": "date"
        },
        "klassenfoto": {
          "$ref": "#/definitions/picture"
        }
      }
    },
    "einzelinitiative": {
      "title": "Einzelinitiative",
      "description": "Als erstes müsst ihr einen Vorschlag formulieren für den Erlass, die Änderung oder die Aufhebung eines Gesetzes, über welches ihr in diesem Planspiel debattieren und abstimmen wollt.",
      "type": "object",
      "properties": {
        "title": {
          "title": "Gebt hier den Titel Eurer Einzelinitiative ein",
          "type": "string"
        },
        "text": {
          "title": "Gebt hier euren Initiativtext ein (max 350 Zeichen)",
          "type": "string",
          "maxLength": 350
        }
      }
    },
    "rollenwahl": {
      "title": "Rollenwahl",
      "description": "Es gibt unterschiedliche Rollen im Kantonsrat. Für das Planspiel wählt jede Schülerin und jeder Schüler eine Rolle.",
      "type": "object",
      "properties": {
        "ratspraesident": {
          "title": "Die Kantonsratspräsidentin oder der Kantonsratspräsident",
          "description": "Die Kantonsratspräsidentin oder der Kantonsratspräsident leitet die Sitzungen des Kantonsrates. Das Präsidium erteilt das Wort und bestimmt die Reihenfolge der Rednerinnen und Redner. Bei Stimmengleichheit entscheidet die Präsidentin oder der Präsident mit Stichentscheid.",
          "$ref": "#/definitions/politiker"
        },
        "vizepraesident": {
          "title": "Die Vizepräsidentin oder der Vizepräsident",
          "description": "Die Vizepräsidentin oder der Vizepräsident unterstützt das Präsidium in allen Belangen. Unter anderem führt die Vizepräsidentin oder der Vizepräsident die Rednerliste.",
          "$ref": "#/definitions/politiker"
        },
        "kommisionsmitglieder": {
          "title": "Kommission",
          "description": "Bevor der Kantonsrat das Geschäft debattiert, wird es von einer Kommission vorberaten. Im Kantonsrat gibt es sieben Sachkommissionen. Bestimmt zuerst die zuständige Kommission und danach mindestens drei Mitglieder der Kommission.",
          "type": "object",
          "properties": {
            "zustaendigekommision": {
              "type": "string",
              "title": "Zuständige Kommission",
              "enum": [
                "Kommission für Bildung und Kultur (KBIK)",
                "Kommission für Energie, Verkehr und Umwelt (KEVU)",
                "Kommission für Justiz und öffentliche Sicherheit (KJS)",
                "Kommission für Planung und Bau (KPB)",
                "Kommission für soziale Sicherheit und Gesundheit (KSSG)",
                "Kommission für Staat und Gemeinden (STGK)",
                "Kommission für Wirtschaft und Abgaben (WAK)"
              ]
            },
            "kommisionsmitglieder": {
              "type": "array",
              "title": "Kommissionsmitglieder",
              "items": {
                "$ref": "#/definitions/politiker"
              },
              "minItems": 3,
              "maxItems": 9
            }
          }
        },
        "regierungsrat": {
          "title": "Der Regierungsrat",
          "description": "Der Regierungsrat ist die Exekutive des Kantons Zürich. Bei einer Einzelinitiative muss er dem Kantonsrat innert neun Monaten mitteilen, ob er die Initiative zur Annahme oder zur Ablehnung empfiehlt. Der Regierungsrat besteht aus sieben Mitgliedern. Bestimmt für das Planspiel mindestens zwei Mitglieder des Regierungsrates. Wählt für diese Regierungsrätin oder diesen Regierungsrat zusätzlich die zuständige Direktion.",
          "type": "object",
          "properties": {
            "zustaending": {
              "title": "Das zuständige Regierungsmitglied",
              "$ref": "#/definitions/politiker"
            },
            "weitere": {
              "type": "array",
              "title": "Weitere Regierungsmitglieder",
              "items": {
                "$ref": "#/definitions/politiker"
              },
              "minItems": 1,
              "maxItems": 8
            }
          }
        },
        "medienvertreter": {
          "title": "Medienvertreterinnen und –vertreter",
          "description": "Die Journalistinnen und Journalisten von Radio, Fernsehen und Zeitungen sind eine sehr wichtige Verbindung zwischen der Politik und der Bevölkerung. Bestimmt eine oder mehrere Personen aus eurer Klasse, welche die Debatte in einem Zeitungsartikel zusammenfassen wird.",
          "type": "array",
          "items": {
            "$ref": "#/definitions/person"
          },
          "minItems": 1
        },
        "weitere": {
          "title": "Weitere Mitglieder des Kantonsrates",
          "type": "array",
          "items": {
            "$ref": "#/definitions/politiker"
          },
          "minItems": 1
        }
      }
    },
    "verfahren": {
      "title": "Das Verfahren",
      "type": "object",
      "properties": {
        "stellungnahmeRegierungsrat": {
          "title": "Stellungnahme des Regierungsrates",
          "description": "Die von Euch bestimmten Regierungsräte debattieren über die Einzelinitiative und erteilen eine begründete Empfehlung.",
          "type": "object",
          "properties": {
            "antrag": {
              "title": "Antrag:",
              "type": "string",
              "enum": [
                "Der Regierungsrat empfiehlt die Einzelinitiative zur Annahme.",
                "Der Regierungsrat empfiehlt die Einzelinitiative zur Ablehnung."
              ]
            },
            "begruendung": {
              "type": "string",
              "title": "Mit folgender Begründung (max 1'000 Zeichen)",
              "maxLength": 1000
            }
          }
        },
        "stellungnahmeKommision": {
          "title": "Stellungnahme der Kommission",
          "description": "Die von Euch bestimmten Kommissionsmitglieder debattieren über die Einzelinitiative und erteilen eine begründete Empfehlung.",
          "type": "object",
          "properties": {
            "antrag": {
              "title": "Antrag:",
              "type": "string",
              "enum": [
                "Die Kommission beantragt Annahme der Einzelinitiative.",
                "Die Kommission beantragt Ablehnung der Einzelinitiative."
              ]
            },
            "begruendung": {
              "type": "string",
              "title": "Mit folgender Begründung (max 1'000 Zeichen)",
              "maxLength": 1000
            }
          }
        },
        "debatte": {
          "title": "Debatte im Kantonsrat",
          "description": "Hier könnt Ihr die fünf wichtigsten Pro- und Kontra-Argumente von fünf Kantonsratsmitgliedern zitieren.",
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "zitierterKantonsrat": {
                "title": "Zitierte Kantonsrätin / zitierter Kantonsrat:",
                "type": "string"
              },
              "bild": {
                "title": "Das zitierte Kantonsratsmitglied während ihrem/seinem Auftritt",
                "$ref": "#/definitions/picture"
              },
              "quote": {
                "title": "Zitat (max 120 Zeichen)",
                "type": "string",
                "maxLength": 120
              }
            }
          },
          "minItems": 5
        }
      }
    },
    "schlussabstimmung": {
      "title": "Wie lautete das Resultat eurer Abstimmung?",
      "type": "object",
      "properties": {
        "resultat": {
          "title": "Ergebnis",
          "type": "string",
          "enum": [
            "Annahme",
            "Ablehnung"
          ]
        },
        "ja": {
          "title": "Ja-Stimmen:",
          "type": "number"
        },
        "nein": {
          "title": "Nein-Stimmen:",
          "type": "number"
        },
        "enthaltungen": {
          "title": "Enthaltungen:",
          "type": "number"
        }
      }
    },
    "medienbericht": {
      "title": "Medienbericht-Erstattung",
      "description": "Wie berichtet und kommentiert die Zeitung \"Die junge Stimme\" die Debatte und die Entscheidung? Eure Journalistin oder euer Journalist kann hier seinen Artikel verfassen.",
      "type": "object",
      "properties": {
        "titel": {
          "title": "Titel",
          "description": "Schreibe hier die treffende Schlagzeile zum Artikel:",
          "type": "string",
          "maxLength": 50
        },
        "bild": {
          "titel": "Titelbild",
          "title": "Upload Bild (Querformat, Grösse < 3MB)",
          "$ref": "#/definitions/picture"
        },
        "lead": {
          "title": "Lead / Zusammenfassung",
          "description": "Schreibe hier eine kurze Zusammenfassung der Debatte.",
          "type": "string",
          "maxLength": 450
        },
        "fliesstext": {
          "title": "Fliesstext",
          "description": "Schreibe hier den Fliesstext des Artikels:",
          "type": "string",
          "maxLength": 1200
        }
      }
    }
  }
}
