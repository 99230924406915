import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import _ from 'lodash'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import config from './config'
import TOS from './TOS'

const deepMap = require('deep-map')
const uuidv4 = require('uuid/v4')

const log = (type: any) => console.log.bind(console, type)

// Min length 6 chars, mixing letters and numbers, or letters and special chars.
const passwordPattern = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/

type AppProps = any

@observer
class App extends React.Component<AppProps> {
  @observable
  enforceAuthentication: string | null = null

  @observable
  passwordInput: string | undefined = undefined

  @observable
  validated: boolean = false

  @observable
  redirect: string | null = null

  @observable
  uid: string | null = null

  constructor (props: any, context: any) {
    super(props, context)

    runInAction(() => {
      this.uid = window.localStorage.getItem('uid')
    })

  }

  @bind
  @action
  clearUid () {
    localStorage.removeItem('uid')
    this.uid = null
    this.redirect = 'registrierung'
  }

  @bind
  @action
  keepUid () {
    // this.redirect = 'bearbeiten/' + this.uid
    this.redirect = 'login'
  }

  @bind
  async createServerEntry (uid: string, passPhrase: string) {
    console.log(`Saving to responses/${uid}`)
    let options: any = {
      url: config.API_BASE_URL + '/' + uid,
      method: 'PUT',
      ContentType: 'application/json',
      data: {},
      headers: {
        Authorization: `Basic ${passPhrase}`
      }
    }

    await axios(options)
  }

  render () {
    if (this.redirect) {
      console.log(`Redirecting from Welcome to ${this.redirect}`)
      return <Redirect to={`/${this.redirect}`} push/>
    }

    return (
      <div className='App'>
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          <div className='App-form'>
            <h2>Schlüpfe in die Rolle eines «Lawmakers»</h2>
            <p>
              Hier können Schülerinnen und Schüler ihre Rolle und den Abstimmungsprozess in Text und Bild als ePortfolio feshalten. Am Ende ensteht ein PDF-Dokument, welches an die ganze Klasse verteilt werden kann.
            </p>
           
            <div className='App-buttongroup'>
              <Button variant='primary' onClick={this.clearUid} className='App-login-button'>Neu registrieren</Button>
              <Button variant='secondary' onClick={this.keepUid} className='App-login-button'>Einloggen</Button>
            </div>
          </div>
        </div>
        <TOS/>
        {/* {
          this.uid && (
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Weitermachen?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Du hast mit diesem Gerät schonmal ein Planspiel durchgeführt.</p>
              <p>Bitte sag uns ob du ein neues Spiel beginnen willst, oder bei deinem bestehenden Spiel weitermachen willst.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.clearUid}>Neues Spiel</Button>
              <Button variant='primary' onClick={this.keepUid} >Weitermachen</Button>
            </Modal.Footer>
          </Modal.Dialog>
          )
        } */}
      </div>
    )
  }

}
export default App
