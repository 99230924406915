import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import _ from 'lodash'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import config from './config'
import TOS from './TOS'

const deepMap = require('deep-map')
const uuidv4 = require('uuid/v4')

const log = (type: any) => console.log.bind(console, type)

// Min length 6 chars, mixing letters and numbers, or letters and special chars.
const passwordPattern = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}/

type AppProps = any

@observer
class App extends React.Component<AppProps> {
  @observable
  enforceAuthentication: string | null = null

  @observable
  passwordInput: string | undefined = undefined

  @observable
  validated: boolean = false

  @observable
  redirect: string | null = null

  @observable
  uid: string | null = null

  @observable
  submitError: string | null = null

  constructor (props: any, context: any) {
    super(props, context)

    runInAction(() => {
      this.uid = window.localStorage.getItem('uid')
    })

  }

  @bind
  @action
  clearUid () {
    localStorage.removeItem('uid')
    this.uid = null
  }

  @bind
  @action
  keepUid () {
    this.redirect = 'bearbeiten/' + this.uid
  }

  @bind
  async createServerEntry (uid: string, passPhrase: string) {
    console.log(`Saving to responses/${uid}`)
    let options: any = {
      url: config.API_BASE_URL + '/' + uid,
      method: 'PUT',
      ContentType: 'application/json',
      data: {},
      headers: {
        Authorization: `Basic ${passPhrase}`
      }
    }

    await axios(options)
  }

  render () {
    if (this.redirect) {
      console.log(`Redirecting from Register to ${this.redirect}`)

      return <Redirect to={`/${this.redirect}`} push/>
    }

    return (
      <div className='App'>
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          <div className='App-form'>
            <h2>Registriere ein neues Planspiel</h2>

              {/* <Alert variant='warning' style={{opacity: 1}}>
                Merkt euch euer Passwort gut! Ohne Passwort habt ihr keinen Zugriff auf eure Daten.
                Wichtig! Aus Datenschutzgründen kann das Passwort weder zurückgesetzt noch abgefragt werden
              </Alert> */}

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ password: '', passwordRepeat: '', acceptTerms: false}}
              validate={values => {
                const errors: any = {}
                if (!passwordPattern.test(values.password)) {
                  errors.password = <div>
                    Das Passwort muss mindestens 8 Zeichen lang sein, aus Gross- und Kleinbuchstaben bestehen und mindestens ein Sonderzeichen oder ein Zahl enthalten. 
                  </div>
                }

                if (values.password !== values.passwordRepeat) {
                  errors.passwordRepeat = <div>Passwörter stimmen nicht überein!</div>
                }

                if (!values.acceptTerms) {
                  errors.acceptTerms = <div>Du musst die Nutzungsbedingen akzeptieren</div>
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                console.log("Submitting")
                const passPhrase = btoa(values.password)
                window.sessionStorage.setItem('passPhrase', passPhrase)
                try {
                  let uid = uuidv4()
                  await this.createServerEntry(uid, passPhrase)

                  window.localStorage.setItem('uid', uid)
                  console.log('Redirecting to ' + uid)

                  runInAction(() => this.redirect = 'bearbeiten/'+uid)
                } catch (e) {
                  runInAction(() => this.submitError = 'Serverfehler, bitte kontaktiere uns falls das Problem weiterbesteht.')
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                // @ts-ignore
                <Form onSubmit={handleSubmit} noValidate validated={this.validated}>
                  <Form.Group controlId='password'>
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control type='password' placeholder='Wähle ein Passwort' value={values.password} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.password} />
                    {errors.password && <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback> }
                  </Form.Group>
                  <Form.Group controlId='passwordRepeat'>
                    <Form.Label>Wiederhole euer Passwort</Form.Label>
                    <Form.Control type='password' placeholder='Gib euer Passwort nochmals ein' value={values.passwordRepeat} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.passwordRepeat}/>
                    {errors.passwordRepeat && <Form.Control.Feedback type='invalid'>{errors.passwordRepeat}</Form.Control.Feedback> }
                  </Form.Group>
                  <div className='App-even-more-margin-top'>
                    ACHTUNG: Bitte speichere und notieren deine automatisch gesetzte Unique URL sowie das von dir gesetzte Passwort. Im Verlustfall können diese Daten aus Datenschutzgründen NICHT MEHR neu gesetzt werden.
                  </div>
                  <Form.Group controlId='acceptTerms' className='App-more-margin-top'>
                    <Form.Check type='checkbox' checked={values.acceptTerms} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.acceptTerms} required label={<span>Ja, mit meiner Registrierung für das Planspiel von THE LAWMAKER stimme ich den <a href='/nutzungsbedingungen'>Nutzungsbedingungen</a> sowie dem darin beschriebenen Umgang mit Daten für alle Seiten und Unterseiten von planspiel.thelawmaker.ch zu.
                      </span>}/>
                    {errors.acceptTerms && <Form.Control.Feedback type='invalid'>{errors.acceptTerms}</Form.Control.Feedback> }
                  </Form.Group>
                  <Button variant='primary' type='submit'>
                    Los gehts!
                  </Button>
                </Form>
              )}
            </Formik>
            {
             this.submitError &&
              <div className='App-server-error'>{this.submitError}</div>
            }
          </div>
        </div>
        <TOS/>
      </div>
    )
  }

}
export default App
