import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import _ from 'lodash'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import config from './config'
import TOS from './TOS'

type AppProps = any

@observer
class Terms extends React.Component<AppProps> {
  render() {
    return (
      <div className='App'>
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          <div className='App-form'>
            <h2>Nutzungsbedingungen</h2>
            <p>
              Diese Nutzungsbedingungen gelten für das Angebot, die Inhalte und die Dienstleistungen der Website planspiel.thelawmaker.ch (inkl. aller Unterseiten) des Betreibers Kantonsrat des Kantons Zürich. Als Besucher der vorliegenden Website erklären Sie sich mit den nachfolgenden Nutzungsbestimmungen einverstanden. Falls Sie die Nutzungsbedingungen dieser Website nicht akzeptieren wollen, bitten wir Sie, die Seite umgehend zu verlassen.
            </p>
            <h3>1. Haftungsausschluss</h3>
            <p>
              Der Kantonsrat Zürich übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
            </p>
            <p>
              Haftungsansprüche gegen den Kantonsrat Zürich wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
            </p>
            <p>
              Der Kantonsrat Zürich schliesst jegliche Haftung betreffend Beschädigungen oder Infizierungen durch Viren von Computern oder anderen Endgeräten im Zusammenhang mit dem Besuch oder dem Gebrauch der vorliegenden Website oder dem Download von auf dieser Webseite enthaltenen Text-, Bild-, Grafik-, Audio- und Videodateien aus.
            </p>
            <p>
              Alle Angebote sind unverbindlich. Der Kantonsrat Zürich behält sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>

            <h3>2. Haftung für Links</h3>
            <p>
              Verweise und Links auf Webseiten Dritter liegen ausserhalb des Verantwortungsbereichs des Kantonsrats Zürich. Es wird jegliche Verantwortung für solche Webseiten Dritter und deren Inhalte abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf
              eigene Gefahr.
            </p>

            <h3>3. Urheberrechte </h3>
            <p>
              Diese Website sowie sämtliche aktuell oder zukünftig darauf existierenden Inhalte (Texte, Bilder, Grafiken, Audiodateien, Videos usw.) sind urheberrechtlich geschützt. Wo nicht anders erwähnt, liegt das Urheberrecht und alle anderen Rechte an diesen Inhalten beim Kantonsrat Zürich, respektive bei den speziell gekennzeichneten Rechteinhabern.
            </p>
            <p>
              Jegliche Wiedergabe, Weiterübertragung oder sonstiger Gebrauch für kommerzielle Zwecke ist ohne Genehmigung durch den Kantonsrat Zürich oder den individuell bezeichneten Rechteinhaber untersagt. Für die Reproduktion jeglicher Elemente ist insbesondere die schriftliche Zustimmung der Rechteinhaber im Voraus einzuholen.
            </p>
            <p>
              Zudem kann diese Website Abbildungen von geschützten Marken und Logos enthalten (gekennzeichnet und nicht gekennzeichnet). Deren Verwendung ist ohne schriftliche Genehmigung der entsprechenden Rechteinhaber strikt untersagt. Durch Nutzung dieser Website werden insbesondere keinerlei Lizenz- oder Eigentumsrechte eingeräumt oder übertragen. Sämtliche auf der vorliegenden Website enthaltenen Hinweise auf Urheber-, Marken- oder sonstige Schutzrechte müssen erhalten bzw. reproduziert werden.
            </p>
            <p>
              Der Kantonsrat des Kantons Zürich kann weder garantieren, noch dafür belangt werden, dass eine Verwendung von Informationen, die auf dieser Website angezeigt werden, nicht Rechte von Drittparteien verletzen, die nicht dem Kantonsrat Zürich gehören oder nicht dem Kantonsrat Zürich angegliedert sind. Sollte der Kantonsrat Zürich auf eine solche Rechtsverletzung hingewiesen werden, wird diese umgehend beseitigt; der Kantonsrat Zürich unternimmt alle hierfür nötigen Massnahmen.
            </p>
            <p>
              Der Kantonsrat Zürich kontrolliert, überprüft und aktualisiert alle Inhalte der Website planspiel.thelawmaker.ch regelmässig. Sollten Inhalte auf dieser Website aufgeführt sein, die als Fehler, Verleumdungen, Beleidigungen, Unterlassungen, Falschmeldungen, Obszönitäten, Pornografie, Lästerung, Gefahren oder Ungenauigkeiten identifiziert werden, bitten wir um umgehende Benachrichtigung an E-Mail-Adresse kathrin.wyss@pd.zh.ch, damit wir die entsprechenden Inhalte berichtigen oder löschen können.
              </p>
            <p>
              Diese Website verbreitet keine ungesetzliche, bedrohliche, diffamierende, obszöne, skandalöse, aufrührerische, pornografische oder lästernde Inhalte und keinerlei Informationen, welche zu kriminellen Handlungen führen, führen können, anstiften oder solche zum Ziel haben.
              </p>
            <p>
              Der Kantonsrat Zürich behält sich das Recht vor, jegliche Informationen und Daten, die von Dritten über diese Website verbreitet werden und gegen die eben genannten Verbote verstossen, sofort zu entfernen.
              </p>
            <p>
              Der Kantonsrat Zürich führt stichprobenartige Prüfungen durch, um sicherzustellen, dass keinerlei widerrechtliche und unsittliche Inhalte publiziert werden.
              </p>
            <h3>4. Datenschutz</h3>
            <h4>4.1 Allgemeines</h4>
            <p>
              Beim Zugriff auf planspiel.thelawmaker.ch werden grundsätzlich keine persönlichen Daten erfasst und gespeichert, mit dem Ziel, das individuelle Nutzerverhalten auszuwerten.
              </p>
            <p>
              Bestimmte Informations- und Transaktionsangebote können indessen nur abgewickelt werden, wenn ein Mindestmass von Personendaten bekannt gegeben worden ist. Dafür gelten die einschlägigen Datenschutzbestimmungen.
              </p>
            <h4>4.2 Non-Tracking-Daten</h4>
            <p>
              Durch Benutzung der Webseite planspiel.thelawmaker.ch entstehen Daten. Teilweise ist die Entstehung der Daten technisch bedingt und erforderlich, teilweise werden Daten aber bewusst erhoben, um Informationen über die Art der Nutzung zu gewinnen und das Angebot kontinuierlich an die Nutzungsbedürfnisse anpassen zu können.
              </p>
            <p>Beim ersten Aufruf der Webseite planspiel.thelawmaker.ch oder einer ihrer Unterseiten wird ein Cookie vom Loadbalancer auf dem System des Besuchers abgelegt. Dieses Cookie enthält einen zufälligen Hashwert, über den der Server definiert wird, an den alle folgenden Anfragen des Systems gerichtet werden. Ziel ist die gleichmässige Lastverteilung auf alle Server.
            </p>
            <p>Beim Aufruf von planspiel.thelawmaker.ch oder einer ihrer direkten Unterseiten wird abhängig von der Grösse des verwendeten Browserfensters die Desktop- oder mobil optimierte Variante der Webseite angezeigt. Wenn diese automatische Einstellung über die Schaltfläche «Zur mobilen Ansicht wechseln» bzw. «Zur-Desktop-Ansicht wechseln» manuell geändert wird, wird die neue Einstellung über ein Cookie auf dem System des Benutzers gespeichert. Das Cookie enthält die gewünschte Einstellung in Form von Text («mobile» oder «desktop») und hat eine Lebensdauer von 20 Jahren.
            </p>
            <p>Eine Weitergabe der Daten an Dritte ist ausgeschlossen.
            </p>
            <p>Die IP-Adressen werden für statistische Zwecke anonymisiert gespeichert.
              </p>
            <h4>4.3 Daten aus Tracking-Tätigkeit</h4>
            <p>
              Die Webseite planspiel.thelawmaker.ch und die direkten Unterseiten verwenden keine Software zur Webanalytik, um so mithilfe anonymisierter Daten Informationen zur Verbesserung des Angebots zu gewinnen. Allerdings werden regelmässig gewisse Informationen wie die Anzahl an Downloads von PDFs in Verbindung mit einer Unique URL, die Gesamtzahl an generierten PDFs durch die Serverseitig erhobenen Zugriffszahlen erhoben.
              </p>
            <p>Die IPv4-Adresse wird für die Speicherung und Verarbeitung um das letzte Byte gekürzt und ist damit anonymisiert.
              </p>
            <p>
              Um unseren Benutzern das Einloggen vereinfachen zu können, …. wird während eines Besuches auf dem System des Benutzers ein Cookie abgelegt (sofern möglich), das die folgenden Daten enthält:
              </p>
            <ul>
              <li>Zufällig generierte eindeutige Dokument-ID</li>
              <li>Zeit des letzten Besuchs dieser Dokument-ID</li>
            </ul>
            <p>
              Die zugeordnete ID ist zufällig und lässt keine Rückschlüsse auf die handelnde Person zu. Das Cookie hat eine Lebensdauer von 31 Tagen und wird bei Besuchen innerhalb dieser Frist um weitere 31 Tage verlängert.
            </p>
            <p>
              Während eines Besuches wird das zugeordnete Passwort zum Dokument mittels des Session-Cookies (sofern möglich) zwischengespeichert. Das Cookie wird mit dem Schliessen des Browserfensters in der Regel automatisch gelöscht. Die Standardeinstellung, bei der Session-Cookies entweder mit dem Schliessen des Tabs oder dem Beenden des Browsers automatisch gelöscht werden, kann manuell oder durch bestimmte Komfortfunktionen übersteuert werden.
            </p>

            <p>
              Das Serverlog wird standardmässig erstellt und enthält alle wesentlichen Informationen eines Zugriffes. Die protokollierten Daten enthalten die folgenden Informationen:
              </p>
            <ul>
              <li>IP-Adresse</li>
              <li>Dokument-ID</li>
              <li>Datum, Uhrzeit und Zeitzone, zu der der Server die Anfrage empfangen hat</li>
              <li>Methode, angefragte Ressource und verwendetes Protokoll</li>
              <li>HTTP Status Code</li>
              <li>Grösse des ausgelieferten Objektes in Bytes</li>
              <li>Browser ID</li>
            </ul>
            <p>
              Die Logfiles werden für einen befristeten Zeitraum aus technischen Gründen vorgehalten und anschliessend gelöscht.
              </p>
            <h4>4.4 Datensicherheit</h4>
            <p>
              Bei der Aufsetzung der Architektur wurde Fokus auf höchstmögliche Sicherheit der Daten gelegt. Wenn ein User das Planspiel unter planspiel.thelawmaker.ch durchführen will, so muss er sich einloggen. Loggt er sich das erste Mal ein, erfolgt serverseitig die Vergabe einer Unique URL. Dann wird der User aufgefordert, sein eigenes Passwort zu definieren. Alle danach gespeicherten User-Daten (Bilder und Texte) werden jeweils verschlüsselt auf dem zum Einsatz gelangenden MS Azure Services (Virtual machine und Storage Account) mit Serverstandort Europa abgelegt.
              </p>
            <p>Die Verschlüsselung erfolgt symmetrisch (ein Passwort wird zur Verschlüsselung der Daten durch Hashing verwendet). Die Verschlüsselung erfolgt serverseitig vor der Speicherung auf der Festplatte der Azure-Speicherdienste, aber mit vom Client bereitgestellten Schlüsseln, so dass die Schlüssel nicht in Azure gespeichert werden (weder in der Virtual Machine, dem Speicherdienst noch in den Protokolldateien). Somit sind die Daten im Ruhezustand immer verschlüsselt und nur die User können auf ihre eigenen Daten zugreifen.
            </p>
            <p>Nur der User selber verfügt über seine individuellen Login-Daten. Ein Password-Retrieval Prozess ist nicht installiert, zudem bestehen keine zugriffsberechtigten Superuser oder Administratoren mit Zugang zu den User-Daten. Ist der User während mehr als 31 Tagen inaktiv, loggt sich nicht ein oder hat seine Login-Daten vergessen, so erfolgt die automatische Löschung seiner Daten.
              </p>
            <h4>5. Schlussbestimmungen</h4>
            <p>
              Die Nutzungsbedingungen einschliesslich aller Haftungsklauseln und Datenschutzbestimmungen unterstehen schweizerischem Recht, daraus erwachsende Streitigkeiten fallen in die ausschliessliche Zuständigkeit der Gerichte in Zürich.
              </p>
            <p>
              Sollten einzelne Bestimmungen ganz oder teilweise unwirksam sein, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.
              </p>
            <p>
              Wenn ein Nutzer Bedenken zur Sicherheit dieser Website, darin enthaltenen Bestandteilen oder zu Urheber- oder Markenrechten hat, bitten wir um Benachrichtigung an: E-Mail-Adresse kathrin.wyss@pd.zh.ch
              </p>
            <p>
              Der Kantonsrat Zürich kann die Nutzungsbedingungen für diese Webseite jederzeit revidieren und die Änderungen an dieser Stelle kommunizieren. Die Nutzer dieser Webseite sind an die jeweils aktuellen Nutzungsbedingungen gebunden.
              </p>
            <p>
              Zürich, 30. Juni 2020
              </p>
            <h4>
              Change Log:
              </h4>
            <ul>
              <li>30. Juni 2020	1. Version</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

}
export default Terms
