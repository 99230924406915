import React, { ComponentProps } from 'react'

import { WidgetProps } from 'react-jsonschema-form'
import { ImageUploader } from './ImageUploader'

function createWidgets (id: string, passPhrase: string | undefined | null) {
  return {
    FileWidget: (props: WidgetProps) => {
      return (
        <ImageUploader
          value={ props.value }
          articleId={ id }
          pathId={ props.id }
          onChange={ (imageLink) => props.onChange(imageLink) }
          passPhrase={ passPhrase }
        />
      )
    }
  }
}
export default createWidgets
