import React from 'react'

function TOS (props: any) {
  return <div className='tos-group'>
    <p className='tos-group-element'>
      <a href="/impressum" >Impressum</a>
    </p>
    <p className='tos-group-element'>
      <a href="/nutzungsbedingungen" >Nutzungsbedingungen &amp; Umgang mit Daten</a>
    </p>
    </div>
}

export default TOS
