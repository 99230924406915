import { observer } from 'mobx-react'
import React, { ChangeEvent, RefObject } from 'react'
import { observable, runInAction } from 'mobx'
import { MdCloudUpload, MdRemoveCircle } from 'react-icons/md'
import { PulseLoader } from 'react-spinners'

import './ImageUploader.css'
import bind from 'bind-decorator'
import axios from 'axios'
import config from '../config'

const CryptoJS = require('crypto-js')

type ImageUploaderProps = {
  value?: string,
  articleId: string,
  pathId: string,
  passPhrase: string | undefined | null,
  onChange: (arg: string | null) => void
}

// takes a password of any length as input and returns that password
// repeated until its length has reached 32 characters
const make32bPass = (pass: any): any => {
  if (pass && pass.length >= 32) return pass.substr(0,32)
  return make32bPass(pass + pass)
}

@observer
export class ImageUploader extends React.Component<ImageUploaderProps> {
  @observable
  uploading: boolean = false

  @observable
  error: string | null = null

  @observable
  imageUrl: string | null = null

  @observable
  imgSrc: string | null | undefined = null

  @observable
  enforceAuthentication: string | null = null

  file: File | null = null

  constructor (props: any, context: any) {
    super(props, context)
    if (this.props.value) {
      this.imageUrl = this.props.value
      this.downloadDecryptedImage(this.imageUrl).then(
        data => {
          runInAction(() => {
            this.imgSrc = data
          })
        }
      ).catch(console.log)
    } else {
      this.imageUrl = null
    }
  }

  @bind
  async clearImage () {

    let { passPhrase } = this.props
    if (!passPhrase || !this.imageUrl) return

    const pass32b = make32bPass(atob(passPhrase))
    const passBase64 = btoa(pass32b)
    const hashBase64 = CryptoJS.SHA256(pass32b).toString(CryptoJS.enc.Base64)

    // Delete image from Azure Storage
    try {
      let res = await axios({
        url: this.imageUrl,
        method: 'DELETE',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-blob-content-type': this.file && this.file.type ? this.file.type : 'image/jpeg',
          'x-ms-encryption-key': passBase64,
          'x-ms-encryption-key-sha256': hashBase64,
          'x-ms-encryption-algorithm': 'AES256'
        }
      })
    } catch (err) {
      console.log(err) // if not successful, we ignore it (will be deleted after 30 days)
    }

    runInAction(() => {
      this.file = null
      this.imageUrl = null
      this.imgSrc = null
      this.props.onChange(null)
    })
  }

  @bind
  async downloadDecryptedImage (url: string | null) {

    if (!url) return null

    let { passPhrase } = this.props
    if (!passPhrase) return

    const pass32b = make32bPass(atob(passPhrase))
    const passBase64 = btoa(pass32b)
    const hashBase64 = CryptoJS.SHA256(pass32b).toString(CryptoJS.enc.Base64)

    try {
      let res = await axios({
        url: url,
        method: 'GET',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-blob-content-type': this.file && this.file.type ? this.file.type : 'image/jpeg',
          'x-ms-encryption-key': passBase64,
          'x-ms-encryption-key-sha256': hashBase64,
          'x-ms-encryption-algorithm': 'AES256'
        },
        responseType: 'arraybuffer'
      })

      let data = new Uint8Array(res.data)
      let blob = new Blob([data], { type: this.file && this.file.type ? this.file.type : 'image/jpeg'})
      let urlCreator = window.URL || window.webkitURL
      let src = urlCreator.createObjectURL(blob)

      return src

    } catch (err) {
      console.log(err)
      return ''
    }
  }

  @bind
  async fileSelected (event: ChangeEvent<HTMLInputElement>) {
    // copy out file from event
    if (event.target && event.target.files && event.target.files.length) {
      this.file = event.target.files[0]
    }

    if (this.file) {
      let { passPhrase } = this.props
      // 1. check for password OR save the form first
      if (!passPhrase) {
        this.error = 'Please save the form before uploading images.'  // Translate to German
        return
      }

      if (this.file.size > 5 * 1024 * 1024) {
        runInAction(() => {
          this.error = 'Datei ist zu gross, maximal 5 MB pro Datei sind erlaubt.'
        })
        return
      }

      // notify that we are uploading
      runInAction(() => {
        this.uploading = true
      })

      try {
        let fileName: string = `${this.props.articleId}_${this.props.pathId}.${this.file.name.toLowerCase().split('.').pop()}`
        let res = await axios.get(`${config.API_BASE_URL}/signed-url/images/${fileName}`)
        this.imageUrl = res.data

        const pass32b = make32bPass(atob(passPhrase))
        const passBase64 = btoa(pass32b)
        const hashBase64 = CryptoJS.SHA256(pass32b).toString(CryptoJS.enc.Base64)

        let result = await axios({
          url: this.imageUrl as string,
          method: 'PUT',
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'x-ms-blob-content-type': this.file && this.file.type ? this.file.type : 'image/jpeg',
            'x-ms-encryption-key': passBase64, // encryption key base64 encoded
            'x-ms-encryption-key-sha256': hashBase64, // encryption key hash base64 encoded
            'x-ms-encryption-algorithm': 'AES256'
          },
          data: this.file
        })

        runInAction(() => {
          this.uploading = false
          this.props.onChange(this.imageUrl)
          console.log(this.imageUrl)
          this.downloadDecryptedImage(this.imageUrl).then(
            data => this.imgSrc = data).catch(console.log)
        })

      } catch (err) {
        console.log(err)
        runInAction(() => {
          this.uploading = false
          this.error = 'Fehler: kann keinen Download-Link erzeugen!'
        })
        return
      }
    }
  }

  render () {
    return <div className='ImageUploader'>
      {
          this.uploading ?
          <div className='ImageUploader-Uploading'>
            <PulseLoader
                size={5}
                color={'#ddd'}
                loading={true}
            />
            <p>Uploading...</p>
          </div>
        : (
          this.imgSrc ?
            <div className='ImageUploader-Upload'>
              <div className='ImageUploader-container'>
                <img src={this.imgSrc}/>
                <div className='ImageUploader-remove' onClick={this.clearImage}> <MdRemoveCircle/></div>
              </div>
            </div>
          :
            <label>
              <div className='ImageUploader-Upload'>
                <MdCloudUpload className='ImageUploader-Icon'></MdCloudUpload>
                { this.error ?
                    <p className='ImageUploader-error'>{this.error}</p>
                  :
                    <p>Klicke hier um ein Bild hochzuladen.</p>}
                <input type='file' onChange={ this.fileSelected } accept='image/*' />
              </div>
            </label>
        )
      }
    </div>
  }
}
