import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import _ from 'lodash'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import config from './config'
import TOS from './TOS'

type AppProps = any

@observer
class Impressum extends React.Component<AppProps> {
  render() {
    return (
      <div className='App'>
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          <div className='App-form'>
            <h2>Impressum</h2>
            <h3>Inhaltliche Verantwortung</h3>
            <p>
            Parlamentsdienste des Kantons Zürich<br/>
            Haus zum Rechberg<br/>
            Hirschengraben 40<br/>
            8090 Zürich<br/>
            +41 43 259 20 08<br/>
            <a href='mailto:kathrin.wyss@pd.zh.ch'>kathrin.wyss@pd.zh.ch</a><br/>
            </p>

            <h3>Konzept &amp; Realisation</h3>
            <p>
            DOCMINE Productions AG<br/>
            Seestrasse 513<br/>
            8038 Zürich<br/>
            +41 44 269 62 80<br/>
            <a href='mailto:info@docmine.com'>info@docmine.com</a><br/>
            <a href='https://www.docmine.com'>www.docmine.com</a><br/>
            </p>
          </div>
        </div>
      </div>
    )
  }

}
export default Impressum
