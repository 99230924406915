export default {
  "klasse": {
    "klassenfoto": {
      "ui:widget": "FileWidget",
      "ui:emptyValue": ""
    }
  },
  "rollenwahl": {
    "ratspraesident": {
      "bild": {
        "ui:widget": "FileWidget"
      }
    },
    "vizepraesident": {
      "bild": {
        "ui:widget": "FileWidget"
      }
    },
    "kommisionsmitglieder": {
      "kommisionsmitglieder": {
        "items": {
          "bild": {
            "ui:widget": "FileWidget"
          }
        }
      }
    },
    "regierungsrat": {
      "zustaending": {
        "bild": {
          "ui:widget": "FileWidget"
        }
      },
      "weitere": {
        "items": {
          "bild": {
            "ui:widget": "FileWidget"
          }
        }
      }
    },
    "medienvertreter": {
      "items": {
        "bild": {
          "ui:widget": "FileWidget"
        }
      }
    },
    "weitere": {
      "items": {
        "bild": {
          "ui:widget": "FileWidget"
        }
      }
    }
  },
  "einzelinitiative": {
    "text": {
      "ui:widget": "textarea"
    }
  },
  "verfahren": {
    "stellungnahmeRegierungsrat": {
      "begruendung": {
        "ui:widget": "textarea"
      }
    },
    "stellungnahmeKommision": {
      "begruendung": {
        "ui:widget": "textarea"
      }
    },
    "debatte": {
      "items": {
        "bild": {
          "ui:widget": "FileWidget"
        }
      }
    }
  },
  "medienbericht": {
    "lead": {
      "ui:widget": "textarea"
    },
    "fliesstext": {
      "ui:widget": "textarea"
    },
    "bild": {
      "ui:widget": "FileWidget"
    }
  }
}
