import React from 'react'
import { observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import Form from 'react-jsonschema-form'
import bind from 'bind-decorator'
import logo from './lawmaker-logo.png'
import './App.css'
import schema from './schemas/input.js'
import uiSchema from './schemas/ui_schema.js'
import { JSONSchema6 } from 'json-schema'
import _ from 'lodash'
import { PulseLoader } from 'react-spinners'
import axios from 'axios'
import createWidgets from './widgets/createWidget'
import config from './config'
import TOS from './TOS'
import fileDownload from 'js-file-download'

const deepMap = require('deep-map')
const uuidv4 = require('uuid/v4')
const CryptoJS = require('crypto-js')

const log = (type: any) => console.log.bind(console, type)

// Min length 6 chars, mixing letters and numbers, or letters and special chars.
const passwordPattern = '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}'

const make32bPass = (pass: any): any => {
  if (pass && pass.length >= 32) return pass.substr(0,32)
  return make32bPass(pass + pass)
}

type AppProps = any

@observer
class App extends React.Component<AppProps> {
  @observable
  formData: any

  @observable
  initialized: boolean = false

  @observable
  loading: string | null = null

  @observable
  error: any = null

  @observable
  info: string | null = null

  @observable
  confirmDelete: boolean = false

  @observable
  enforceAuthentication: string | null = null

  @observable
  passwordInput: string | undefined = undefined

  passPhrase: string | undefined | null = null
  authForm: any = null
  currentForm: Form<any> | null = null
  formSaveResolver: (() => void) | null = null

  id: string

  constructor (props: any, context: any) {
    super(props, context)
    
    console.log("<< APP CONSTRUCTOR >> ")


    this.authForm = React.createRef()
    this.passPhrase = window.sessionStorage.getItem('passPhrase')
    this.id = this.getId() // gets uid from params or session storage

    if (this.id) {
      if (this.passPhrase) {
        this.getData(this.id)
        console.log(this.formData)
      } else {
        this.enforceAuthentication = 'retrieving'
      }
    } else {
      // generates new id (first time user) and doesnt request password yet
      this.id = uuidv4()
      // window.localStorage.setItem('uid', this.id)
      this.props.history.push('/' + this.id) // redirects to /<uid>
    }

    this.initialized = true
  }

  @bind
  getId () {
    if (this.props.match.params.uid) {
      // looks for uid in the url params
      return this.props.match.params.uid
    } else {
      // looks for uid in the stored cookies
      let uid: string | null = window.localStorage.getItem('uid')
      return uid
    }
  }

  @bind
  setPassPhrase (pass: string | undefined | null) {
    runInAction(() => {
      if (!pass) {
        window.sessionStorage.removeItem('passPhrase')
      } else {
        pass = btoa(pass) // base64 encoding
        window.sessionStorage.setItem('passPhrase', pass)
      }
      this.passPhrase = pass
    })
  }

  @bind
  getData (id: string): void {
    console.log(`Getting data ${id}`)
    let options: any = {
      url: config.API_BASE_URL + '/' + id,
      method: 'GET',
      headers: {
        Authorization: `Basic ${this.passPhrase}`
      }
    }
    axios(options)
      .then((res: any) => {
        this.formData = JSON.parse(res.data)
      })
      .catch((err: any) => {
        this.formData = null
        if (err.response && (err.response.status === 401 || err.response.status === 404)) {
          this.error = err.response.data
        } else {
          console.log(err.response)
          this.error = 'Unkown error, try again later.'
        }
      })
  }

  @bind
  async saveData (data: any) {
    if (!this.initialized) {
      return
    }
    if (!this.passPhrase) {
      this.enforceAuthentication = 'saving'
      return
    }

    runInAction(() => {
      this.loading = 'Speichern...'
    })

    // sanitize form data - change "undefined" values to "null"
    data.formData = deepMap(
      data.formData,
      (element: any, key: string | number) => {
        if (element === undefined) {
          return null
        } else {
          return element
        }
      }
    )

    this.formData = data.formData

    console.log(`Saving to responses/${this.id}`, data.formData)
    let options: any = {
      url: config.API_BASE_URL + '/' + this.id,
      method: 'PUT',
      ContentType: 'application/json',
      data: this.formData,
      headers: {
        Authorization: `Basic ${this.passPhrase}`
      }
    }

    axios(options)
    .then((res: any) => {
      runInAction(() => {
        this.loading = null
        console.log('Done saving')
        if (this.formSaveResolver) {
          this.formSaveResolver()
        }
      })
    })
    .catch((err: any) => {
      console.error(err.response.data)
      runInAction(() => {
        this.error = err.response.data
      })
    })
  }

  @bind
  async deleteStoredData () {
    if (!this.initialized) {
      return
    }

    runInAction(() => {
      this.loading = 'Löschen...'
    })

    let options: any = {
      url: config.API_BASE_URL + '/' + this.id,
      method: 'DELETE',
      headers: {
        Authorization: `Basic ${this.passPhrase}`
      }
    }
    axios(options).then((res: any) => {
      runInAction(() => {
        this.formData = null
        this.loading = null
        this.info = 'Data deleted successfully!'
      })

      localStorage.clear()
    }).catch((err: any) => {
      console.log(err)
      this.error = err.response.data
      this.loading = null
    })
  }

  @bind
  async generatePDF () {
    if (this.currentForm) {
      console.log('this.currentForm')
      let formSaver = new Promise((resolve, reject) => {
        this.formSaveResolver = resolve
      })

      console.log('Starting save')
      this.currentForm.submit()

      await formSaver
      console.log('Saved, loading pdf')

      runInAction(() => {
        this.loading = 'Erzeuge PDF...'
        console.log('Creating PDF')
      })

      try {
        console.log('Starting post')
        // To do: change the url to instance endpoint
        let result = await axios({
          url: config.API_BASE_URL + '/create-pdf/' + this.id,
          method: 'POST',
          headers: {
            Authorization: `Basic ${this.passPhrase}`
          }
        })
        console.log('Post is done. Result: ', result)

        await this.downloadPDF()
      } catch (e) {
        console.error(e)
        runInAction(() => {
          if (e.message) {
            this.error = e.message
          } else {
            this.error = 'Unknown error'
          }
        })
      } finally {
        runInAction(() => {
          this.loading = null
          console.log('Done creating PDF')
        })
      }
    }
  }

  @bind
  @action
  login (origin: string | null) {
    this.setPassPhrase(this.passwordInput)
    this.enforceAuthentication = null
    if (origin === 'saving') {
      this.currentForm && this.currentForm.submit()
    } else if (origin === 'deleting') {
      this.deleteStoredData().then().catch()
    } else if (origin === 'retrieving') {
      this.getData(this.id)
    }
  }

  @bind
  async downloadPDF () {

    if (!this.passPhrase) {
      return
    }

    try {
      console.log(`Requesting signed url: ${config.API_BASE_URL}/signed-url/reports/report-${this.id}.pdf`)
      let res = await axios.get(`${config.API_BASE_URL}/signed-url/reports/report-${this.id}.pdf`)
      let reportUrl = res.data
      console.log(`ReportURL: ${reportUrl}`)

      const pass32b = make32bPass(atob(this.passPhrase))
      const passBase64 = btoa(pass32b)
      const hashBase64 = CryptoJS.SHA256(pass32b).toString(CryptoJS.enc.Base64)

      let response = await axios({
        url: reportUrl,
        method: 'GET',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-blob-content-type': 'application/pdf',
          'x-ms-encryption-key': passBase64, // encryption key base64 encoded
          'x-ms-encryption-key-sha256': hashBase64, // encryption key hash base64 encoded
          'x-ms-encryption-algorithm': 'AES256'
        },
        responseType: 'arraybuffer'
      })

      if (response) {
        fileDownload(response.data, `thelawmaker-planspiel-${this.id}.pdf`)
      } else {
        throw Error()
      }

    } catch (err) {
      console.log(err)
      runInAction(() => {
        this.error = 'Fehler: kann keinen Download-Link erzeugen!'
      })
      return
    }

  }


  render () {
    let formContent

    console.log('App - Rendering!')

    if (this.initialized) {
      formContent = (
        <div className='App-form'>
          <div className='App-top-buttons'>
            <button
              className='btn btn-danger'
              onClick={() => {
                this.confirmDelete = true
              }}
            >
              Löschen
            </button>
            <button
              className='btn btn-info'
              onClick={() => this.currentForm && this.currentForm.submit()}
            >
              Speichern
            </button>
            <button className='btn btn-info' onClick={() => this.generatePDF()}>
              Erzeuge PDF
            </button>
            {/* <button className='btn btn-info' onClick={() => this.downloadPDF()}>
              PDF runterladen
            </button> */}
          </div>

          <div className='App-uid-area'>
            <p>Unique ID: <b>{this.id}</b> </p>
            <p>Merkt euch eure Unique ID und Passwort gut! Im Verlustfall können eure Daten NICHT MEHR geladen werden. </p>
          </div>

          <Form
            widgets={createWidgets(this.id, this.passPhrase)}
            schema={schema as JSONSchema6}
            uiSchema={uiSchema}
            liveValidate={true}
            disabled={this.loading !== null}
            formData={this.formData}
            onSubmit={this.saveData}
            // tslint:disable-next-line: block-spacing
            onChange={_.debounce((form) => { if (form.edit) { this.formData = form.formData }}, 2000)}
            onError={log('errors')}
            ref={form => {
              this.currentForm = form
            }}
          >
            <button
              className='btn btn-info'
              type='submit'
              // onClick={() => this.currentForm && this.currentForm.submit()}
            >
              Speichern
            </button>
          </Form>

        </div>
      )
    } else {
      formContent = (
        <div className='App-loader'>
          <PulseLoader
            sizeUnit={'px'}
            size={30}
            color={'#ea575b'}
            loading={true}
          />
          <p>Laden...</p>
        </div>
      )
    }

    // OVERLAY
    let overlay: any = null
    console.log('enforceAuthentication', this.enforceAuthentication)
    if (this.enforceAuthentication) {
      // the origin states which action set the enforceAuthentication value:
      // saving, deleting or retrieving
      let origin: string | null = this.enforceAuthentication
      overlay = (
        <div className='App-loader-overlay'>
          <h3>Gib bitte dein Passwort ein</h3>
          <form className='App-auth-form' ref={this.authForm} noValidate onSubmit={() => this.login(origin)}>
            <input
              className='input-field'
              type='password'
              id='password'
              placeholder='Passwort'
              onBlur={ (e) => { this.passwordInput = e.target.value } }
              onChange={ (e) => {
                runInAction(() => {
                  this.passwordInput = e.target.value
                })
              }}
              required
            />
            <button
              className='btn btn-success App-login-button'
              type='submit'
            >
              Einloggen
            </button>
          </form>
        </div>
      )
    } else if (this.error) {
      console.log('Error', this.error)
      let error = this.error
      if (error.includes('phrase')) {
        error = 'Falsches Passwort!'
      } else if (error.includes('Resource')) {
        error = <>
                  Dokument nicht gefunden!
                  <br/>
                  Entweder gibt es dein Dokument nicht, oder es wurde automatisch nach 30 Tagen gelöscht.
                </>
      }

      overlay = (
        <div className='App-loader-overlay'>
          <p className='App-error-message'>{error}</p>
          <button
            className='btn btn-info'
            onClick={() => {
              runInAction(() => {
                if (this.error.includes('Resource')) {
                  // 404 when fetching form data: get new id and start over
                  this.error = null
                  this.props.history.push('/')
                } else if (this.error.includes('phrase')) {
                  // 401 due to wrong or missing passphrase: enforce auth
                  this.error = null
                  this.setPassPhrase(null)
                  this.enforceAuthentication = 'retrieving'
                } else {
                  // all other errors: simply close the overlay
                  this.error = null
                }
              })
            }}
          >
            Close
          </button>
        </div>
      )
    } else if (this.info) {
      overlay = (
        <div className='App-loader-overlay'>
          <p className='App-error-message'>{this.info}</p>
          <button
            className='btn btn-info'
            onClick={() => {
              runInAction(() => {
                this.info = null
              })
            }}
          >
            Close
          </button>
        </div>
      )
    } else if (this.confirmDelete) {
      overlay = (
        <div className='App-loader-overlay'>
          <h3>Bist du dir sicher?</h3>
          <p className='App-error-message'>
            Danach gibt es kein zurück mehr, deine Daten werden hiermit unwiederruflich gelöscht.
          </p>
          <div className='App-confirm-delete-buttons'>
          <button
            className='btn btn-danger button-margin'
            onClick={() => {
              runInAction(() => {
                this.confirmDelete = false
                this.deleteStoredData().then().catch()
              })
            }}
          >
            Daten löschen
          </button>

          <button
            className='btn btn-info button-margin'
            onClick={() => {
              runInAction(() => {
                this.confirmDelete = false
              })
            }}
          >
            Abbrechen
          </button>
          </div>
        </div>
      )
    } else if (this.loading) {
      overlay = (
        <div className='App-loader-overlay'>
          <PulseLoader
            sizeUnit={'px'}
            size={30}
            color={'#ea575b'}
            loading={true}
          />
          <p>{this.loading}</p>
        </div>
      )
    }

    return (
      <div className='App'>
        {overlay}
        <div className='App-inner'>
          <header>
            <img src={logo} className='App-logo' alt='logo' />
          </header>
          {formContent}
          <TOS/>
        </div>
      </div>
    )
  }
}
export default App
