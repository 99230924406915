import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Link, BrowserRouter , Switch, Redirect } from 'react-router-dom'
import './index.css'
import App from './App'
import Welcome from './Welcome'
import Register from './Register'
import Login from './Login'
import Terms from './Terms'
import Impressum from './Impressum'

import * as serviceWorker from './serviceWorker'

const routing = (
  <BrowserRouter>
    <Switch>
      <Route path='/nutzungsbedingungen' component={Terms}/>
      <Route path='/impressum' component={Impressum}/>
      <Route path='/registrierung' component={Register}/>
      <Route path='/login' component={Login}/>
      <Route path='/bearbeiten/:uid' component={App} />
      <Route path='/' component={Welcome}/>
      {/* <Route path='/'>
        <Redirect to="/willkommen" push/>
      </Route> */}
    </Switch>
  </BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
